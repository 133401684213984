@import "_colors";

.font-base {
  font-weight: $weightMedium;
  font-size: 12px;
  line-height: 16px;
  color: $fontColor;
}

@mixin font-base {
  font-weight: $weightMedium;
  font-size: 12px;
  line-height: 16px;
  color: $fontColor;
}

@mixin font-title-bold {
  font-weight: $weightBold;
  font-size: 16px;
  line-height: 24px;
}

@mixin font-title-bold-big {
  font-weight: $weightBold;
  font-size: 18px;
  line-height: 24px;
}
