@import "node_modules/amdocs-core-package/lib/assets/scss/core";
@import "node_modules/amdocs-core-package/lib/assets/scss/scroll";
@import "node_modules/amdocs-core-package/lib/assets/scss/datepicker";
@import "node_modules/amdocs-core-package/lib/assets/scss/mixin";
@import "../../../node_modules/ngx-toastr/toastr";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "shared-variables";
@import "table";
@import "dropdown";

body{
    margin:0 !important;
    padding:0 !important;
}
body.is-overlay-active {
    pointer-events: none; /* Disable clicks on elements except the overlay */
}
@media (min-resolution: 82dpi) and (max-resolution: 102dpi) {
    /* Your styles here */
    body {
        zoom:95%;
   }
  }

  @media (min-resolution: 82dpi) and (max-resolution: 102dpi) and (min-width: 1600px) and (max-width: 1600px) {
    body {
        zoom: 80%;
    }
  }

//   @media (min-resolution: 82dpi) and (max-resolution: 102dpi) and (min-width: 1025px) and (max-width: 1025px) {
//     body {
//         zoom: 77%;
//     }
//   }


@media (min-resolution: 181dpi) and (max-resolution: 200dpi) {
    body {
         zoom: 71%;  //mac 1440
    }
    .small-font, .control-label{
        font-size: 90% !important;
    }
   
}

@media (min-resolution: 161dpi) and (max-resolution: 180dpi) {
    body {
         zoom: 55%;
    }
    .small-font, .control-label{
        font-size: 90% !important;
    }
   
}

@media (min-resolution: 141dpi) and (max-resolution: 160dpi) {
    body {
         zoom: 52.7%; //zoom: 66.7%;
    }
    .small-font, .control-label{
        font-size: 90% !important;
    }
   
}



@media (min-resolution: 141dpi) and (max-resolution: 160dpi) and (min-width: 1025px)  {
    body {
        zoom: 63.7%;
    }
  }
  @media (min-resolution: 141dpi) and (max-resolution: 160dpi) and (max-width: 1186px)  {
    body {
        zoom: 52.7%;
    }
  }


@media (min-resolution: 121dpi) and (max-resolution: 140dpi) {
    body {
         zoom: 70%;
    }
    .small-font, .control-label{
        font-size: inherit !important;
    }
}
@media (min-resolution: 101dpi) and (max-resolution: 115dpi) {
    body {
         zoom: 91%;
    }
   
}

@media (min-resolution: 116dpi) and (max-resolution: 120dpi) {
    body {
         zoom: 63%; //zoom: 80%;
    }
    .small-font, .control-label{
        font-size: 90% !important;
    }
}

@media (min-resolution: 116dpi) and (max-resolution: 120dpi)  and (min-width: 1025px) {
    body {
         zoom: 77%; //zoom: 80%;
    }
    .small-font, .control-label{
        font-size: 90% !important;
    }
}



@media (min-resolution: 116dpi) and (max-resolution: 120dpi)  and (max-width: 1600px) {
    body {
         zoom: 64%; //zoom: 80%;
    }
    .small-font, .control-label{
        font-size: 90% !important;
    }
}



@media (min-resolution: 116dpi) and (max-resolution: 120dpi)  and (max-width: 1536px) {
    body {
         zoom: 77%; //zoom: 80%;
    }
    .small-font, .control-label{
        font-size: 90% !important;
    }
}

@media (min-resolution: 116dpi) and (max-resolution: 120dpi)  and (max-width: 1280px) {
    body {
         zoom: 64%; //zoom: 80%;
    }
    .small-font, .control-label{
        font-size: 90% !important;
    }
}
  
@media (min-resolution: 84dpi) and (max-resolution: 104dpi) and (max-width: 1024px) {
    body {
        zoom: 85%;//mac
    }
}

@media (min-resolution: 181dpi) and (max-resolution: 200dpi) and (max-width: 1280px) {
    body {
        zoom: 64%;//mac
    }
}

@media (min-resolution: 181dpi) and (max-resolution: 200dpi) and (max-width: 1440px) {
    body {
        zoom: 64%; //mac
    }
}

@media (min-resolution: 181dpi) and (max-resolution: 200dpi) and (max-width: 1024px) {
    body {
        zoom: 50%; //mac
    }
}

