.btn {
  cursor: pointer;
  border-radius: 8px;
  @include font-base;

  &.btn-medium {
    width: 128px;
    height: 40px;
    padding: 0;

    &.icon-button {
      padding: 10px 24px 10px 16px;
    }
  }

  &.btn-large {
    width: 186px;
    height: 40px;
    padding: 0;

    &.icon-button {
      padding: 8px 24px 8px 16px;
    }
  }

  &.secondary {
    font-weight: 500;
    font-size: 14px;
    color: $fontColor;

    &:hover {
      color: $colorP2;
      background-color: $colorD7;
    }
  }

  &.dark {
    color: $white;

    &:hover {
      color: white;
    }
  }

  &.white {
    border: 1px solid $black;

  }

  &.btn-link {
    cursor: pointer;
    text-decoration: underline;
  }

}

.underline-link {
  cursor: pointer;
  text-decoration: underline;
}


.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
}