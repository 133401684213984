.modal {
  display:flex !important;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align:center;
  .modal-dialog {
    min-width: 984px !important;
  }
  .modal-content {
    &.create-learning-plan {
      width: 760px;
    }

    &.add-learning-plan-item {
      width: 1030px;
    }

    &.feedback-popup {
      width: 758px;
    }

    .mh-popup-header {
      text-align: left;
      border-bottom: 1px solid $mhLightGray;
      padding: 24px 0 24px 30px;
    }
  }

  ::ng-deep .modal.show .modal-dialog {
    transition: transform 0.1s ease-out !important;
    transform: translate(0, -50px);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: fixed;
    min-width: 984px !important;
}

  .modal-lg {
    .modal-content {
      width: 984px;
      min-height: 548px;
      padding: 0px;
     // width: 100px;
    //  position: fixed;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
     // width: 50%;
    }
  }
  .max-plan-limit {
    .modal-content {
      width: 760px;
    }
  }
}
