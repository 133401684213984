.custom-input-label {
  @include font-base;
  margin-bottom: 4px;
}

.custom-input-field-control {
  height: 32px;
  border: 1px solid $fontColor;
  border-radius: 6px;
  padding-left: 34px;
  border-color: #82889c;
  

  .selected-value {
    display:none;
    @include font-base;
    &.placeholder{
      background-color: transparent !important;
    }
  }
}




