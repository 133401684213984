
.rc-table-wrapper {
  background: $white;
  box-shadow: 0px 2px 8px rgb(19 19 24 / 16%);
  border-radius: 8px;
  padding: 15px 2px 24px;

  .amdocs-table-headers-wrapper {

    .rc-table-header {
      font-weight: 700;
      font-size: 16px;
      color: $mhGray;
    }
  }

  .rc-table-row-wrapper{
    cursor: pointer;
    border-bottom: none !important;
  }

}

.rc-table-cell {
  font-weight: 700 !important;
  font-size: 18px !important;
  color: $fontColor;
}
